@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,html, #root{
  height:100%;
}
body{
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  background: #fff;
}
.PrivateNotchedOutline-legendNotched-64 {
  max-width: 101px!important;
  -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
}

.custom-control-label::before {
  position: absolute;
  top: -5px;
  left: -35px;
  display: block;
  width: 30px;
  height: 30px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #007bff solid 1px;
}
.custom-control-input {
  position: absolute;
  left: -12px;
    top: -4px;
  z-index: -1;
  width: 30px;
  height: 30px;
  opacity: 0;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: none;
  top: -4px;
  left: -34px;
  display: block;
  width: 28px;
  height: 28px;
  background-color: #007bff;
  border: solid 4px #fff;
  border-radius: 30px;
}

.custom-control-label::after {
  position: absolute;
  top: -5px;
  left: -35px;
  display: block;
  width: 30px;
  height: 30px;
  content: "";
  background: no-repeat 50%/50% 50%;
  border-radius: 30px;
}
.custom-control-label {
  text-transform: uppercase;
  margin-left: 14px;
}
.btn-outline-primary {
  border-radius: 30px;
    text-transform: uppercase;
    font-size: 16px;
    padding: 10px 40px;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #fff;
}
.btn-outline-default{
  text-transform: uppercase;
}

.obrigado h1{
  font-size: 40px!important;
  font-weight: 600;
  text-transform: uppercase;
}
.obrigado h2, .obrigado p{
  text-align: center;
}
.logo{
  width: 100px;
  border-radius: 50px;
  border: solid 2px #ccc;
box-shadow: 0px 0px 15px 0px rgba(143,143,143,1);
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none;
}
